import { useState, useEffect } from "preact/hooks";
import { useSignal } from "@preact/signals";
import { ThemeToggle } from "./ThemeToggle.tsx";
import { getSiteConfig } from "../config/sites.ts";
import { Icons } from "../config/icons.tsx";

// CSS classes
const DESKTOP_NAV_LINK = "border-transparent text-gray-500 dark:text-gray-300 hover:text-[var(--color-primary)] hover:border-[var(--color-primary)] inline-flex items-center px-2 py-1 border-b-2 text-sm font-medium";
const MOBILE_NAV_LINK = "border-transparent text-gray-500 hover:bg-gray-50 dark:hover:bg-gray-700 hover:border-gray-300 hover:text-gray-700 dark:text-gray-300 dark:hover:text-white block pl-2 pr-3 py-1.5 border-l-4 text-base font-medium";
const MOBILE_NAV_LINK_ACTIVE = "text-[var(--color-primary)] block pl-2 pr-3 py-1.5 border-l-4 border-[var(--color-primary)] text-base font-medium";
const AUTH_BUTTON_SIGNIN = "bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-200 hover:bg-gray-200 dark:hover:bg-gray-600 px-3 py-2 rounded-md text-sm font-medium transition-colors";
const AUTH_BUTTON_SIGNUP = "bg-[var(--color-primary)] text-white hover:bg-opacity-90 px-3 py-2 rounded-md text-sm font-medium transition-colors";
const MOBILE_MENU_SEPARATOR = "border-b border-gray-200 dark:border-gray-700 my-2";

declare global {
  interface Window {
    SITE_NAME: string;
  }
}

export function NavBar() {
  const [isOpen, setIsOpen] = useState(false);
  const config = useSignal(getSiteConfig());
  
  useEffect(() => {
    if (window.SITE_NAME) {
      config.value = getSiteConfig();
    }
  }, []);

  const showDebug = typeof window !== 'undefined' 
    ? new URLSearchParams(window.location.search).get('debug') === '1'
    : false;

  // Process navigation config from site config
  const navConfig = config.value?.navigation || {};

  // Feature flags and navigation labels processed from config
  const SHOW_BLOG_NAV = navConfig.showBlog ?? true;
  const SHOW_NEWS_NAV = navConfig.showNews ?? true;
  const SHOW_HELP_NAV = navConfig.showHelp ?? true;
  const SHOW_TECHNOLOGY_NAV = navConfig.showTechnology ?? true;
  const SHOW_SOLUTIONS_NAV = navConfig.showSolutions ?? true;
  const SHOW_AUTH_NAV = navConfig.showAuth ?? true;
  const PORTFOLIO_NAV_LABEL = navConfig.portfolioLabel ?? "Portfolio";

  return (
    <>
      {showDebug && (
        <div style="position: fixed; bottom: 10px; left: 10px; background: rgba(0,0,0,0.8); color: white; padding: 10px; z-index: 9999; font-family: monospace; font-size: 12px;">
          <pre>
            Config: {JSON.stringify(config.value, null, 2)}
            Company name: {config.value?.company?.name}
          </pre>
        </div>
      )}

      <nav class="bg-white dark:bg-gray-800 shadow">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div class="flex justify-between h-16">
            {/* Left side with logo and first nav group */}
            <div class="flex">
              <div class="flex-shrink-0 flex items-center">
                <a href="/" class="flex items-center">
                  {Icons.logo}
                  <span class="text-xl font-bold text-[var(--color-primary)]">
                    {config.value?.company?.name}
                  </span>
                </a>
              </div>
              {/* First navigation group */}
              <div class="hidden sm:ml-6 sm:flex">
                <a href="/" class={DESKTOP_NAV_LINK}>Home</a>
                <a href="/portfolio" class={DESKTOP_NAV_LINK}>{PORTFOLIO_NAV_LABEL}</a>
                {SHOW_TECHNOLOGY_NAV && (
                  <a href="/technology" class={DESKTOP_NAV_LINK}>Technology</a>
                )}
                {SHOW_SOLUTIONS_NAV && (
                  <a href="/solutions" class={DESKTOP_NAV_LINK}>Solutions</a>
                )}
              </div>
            </div>

            {/* Right side with second nav group, theme toggle, and auth */}
            <div class="hidden sm:flex sm:items-center">
              <a href="/about" class={DESKTOP_NAV_LINK}>About</a>
              {SHOW_NEWS_NAV && (
                <a href="/news" class={DESKTOP_NAV_LINK}>News</a>
              )}
              {SHOW_BLOG_NAV && (
                <a href="/blog" class={DESKTOP_NAV_LINK}>Blog</a>
              )}
              {SHOW_HELP_NAV && (
                <a href="/help" class={DESKTOP_NAV_LINK}>Help</a>
              )}
              <ThemeToggle />
              {SHOW_AUTH_NAV && (
                <div class="flex items-center space-x-4">
                  <a href="/signin" class={AUTH_BUTTON_SIGNIN}>Sign In</a>
                  <a href="/#contact" class={AUTH_BUTTON_SIGNUP}>Sign Up</a>
                </div>
              )}
            </div>

            {/* Mobile menu button */}
            <div class="flex items-center sm:hidden">
              <button
                onClick={() => setIsOpen(!isOpen)}
                class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[var(--color-primary)]"
              >
                <span class="sr-only">Open main menu</span>
                {!isOpen ? Icons.menuOpen : Icons.menuClose}
              </button>
            </div>
          </div>
        </div>

        {/* Mobile menu */}
        <div class={`${isOpen ? "block" : "hidden"} sm:hidden`}>
          <div class="pt-2 pb-3 space-y-1">
            <a href="/" class={MOBILE_NAV_LINK_ACTIVE}>Home</a>
            <a href="/portfolio" class={MOBILE_NAV_LINK}>{PORTFOLIO_NAV_LABEL}</a>
            {SHOW_TECHNOLOGY_NAV && (
              <a href="/technology" class={MOBILE_NAV_LINK}>Technology</a>
            )}
            {SHOW_SOLUTIONS_NAV && (
              <a href="/solutions" class={MOBILE_NAV_LINK}>Solutions</a>
            )}
            <div class={MOBILE_MENU_SEPARATOR} />
            <a href="/about" class={MOBILE_NAV_LINK}>About</a>
            {SHOW_NEWS_NAV && (
              <a href="/news" class={MOBILE_NAV_LINK}>News</a>
            )}
            {SHOW_BLOG_NAV && (
              <a href="/blog" class={MOBILE_NAV_LINK}>Blog</a>
            )}
            {SHOW_HELP_NAV && (
              <a href="/help" class={MOBILE_NAV_LINK}>Help</a>
            )}
            {SHOW_AUTH_NAV && (
              <>
                <div class={MOBILE_MENU_SEPARATOR} />
                <a href="/signin" class={AUTH_BUTTON_SIGNIN}>Sign In</a>
                <a href="/#contact" class={AUTH_BUTTON_SIGNUP}>Sign Up</a>
              </>
            )}
          </div>
        </div>
      </nav>
    </>
  );
}
